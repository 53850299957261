<template>
  <div class="section-wrapper">
    <b-row>
      <b-overlay :show="userLoading">
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:headerTitle>
            <h5 class="card-title" style="font-weight:550;"> {{ $t('policy_advocacy.policy_information') }}</h5>
          </template>
          <template v-slot:body>
            <b-row>
                  <b-col lg="12" sm="12">
                   <b-table-simple hover small caption-top responsive striped bordered>
                     <b-tr>
                      <b-th style="width:20%">{{ $t('meetingManagement.memo_no') }}</b-th>
                      <b-td style="width:30%">{{ item.memo_no }}</b-td>
                      <b-th style="width:20%">{{ $t('bfti.submission_date') }}</b-th>
                      <b-td style="width:30%">{{ item.submission_date | dateFormat }}</b-td>
                    </b-tr>
                     <tr>
                       <th> {{$t('policy_advocacy.policy_title')}}</th>
                       <td>{{currentLocale === 'bn'? item.policy_title_bn : item.policy_title}}</td>
                       <th> {{ $t('bfti.attachment')}}</th>
                       <td>
                         <a v-if="item.attachment" class="btn btn-success btn-sm rounded-pill mr-1" target="_blank" :href="baseUrl + 'download-attachment?file=' + item.attachment" title="attachment"><i class="ri-download-cloud-fill"></i></a>
                         <a target="_blank" v-if="item.attachment" :href="baseUrl + item.attachment">{{ $t('bfti.attachment_down')}}</a>
                       </td>
                     </tr>
                      <tr>
                       <th> {{$t('policy_advocacy.description_instruction')}}</th>
                       <td colspan="3">{{currentLocale === 'bn'? item.description_bn : item.description}}</td>
                     </tr>
                   </b-table-simple>
                  </b-col>
            </b-row>
          </template>
        </body-card><body-card>
          <template v-slot:headerTitle>
            <h5 class="card-title" style="font-weight:550;"> {{ $t('policy_advocacy.focal_point_information') }}</h5>
          </template>
          <template v-slot:body>
            <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple hover small caption-top responsive striped bordered>
                      <tr>
                       <th style="width: 20%"> {{$t('globalTrans.name')}}</th>
                       <td style="width: 30%">{{currentLocale === 'bn'? item.name_bn : item.name}}</td>
                       <th style="width: 20%"> {{$t('globalTrans.designation')}}</th>
                       <td style="width: 30%">{{currentLocale === 'bn'? item.designation_bn : item.designation}}</td>
                      </tr>
                      <tr>
                        <th> {{ $t('bfti.organization')}}</th>
                        <td>{{currentLocale === 'bn'? item.organization_bn : item.organization}}</td>
                        <th> {{ $t('bfti.contact_number')}}</th>
                        <td>{{item.contact_no | mobileNumber}}</td>
                      </tr>
                      <tr>
                        <th> {{ $t('bfti.email')}}</th>
                        <td colspan="3">{{item.email}}</td>
                      </tr>
                     </b-table-simple>
                  </b-col>
            </b-row>
          </template>
        </body-card>
        <body-card>
          <template v-slot:headerTitle>
            <h5 class="card-title" style="font-weight:550;"> {{ $t('globalTrans.forward') }} {{ $t('globalTrans.info') }}</h5>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <div class="table-wrapper table-responsive">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                          <th scope="col"> {{ $t('researchRequestManagement.received_by') }} </th>
                          <th scope="col"> {{ $t('globalTrans.designation') }} </th>
                          <th scope="col"> {{ $t('globalTrans.comments') }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(details, index) in forwardList" :key="index">
                          <td>
                            {{ getNameReceiver(details.receiver_id) }}
                          </td>
                          <td>
                            {{ getForwardUserDesignation(details.receiver_id) }}
                          </td>
                          <td>
                            {{ currentLocale === 'bn' ? details.note_bn : details.note }}
                          </td>
                        </tr>
                    </tbody>
                </table>
                </div>
              </b-col>
            </b-row>
          </template>
        </body-card>
        <body-card>
          <template v-slot:headerTitle>
            <h5 class="card-title" style="font-weight:550;"> {{ $t('researchRequestManagement.appr_reject_info') }}</h5>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <div class="table-wrapper table-responsive">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                          <th scope="col"> {{ $t('researchRequestManagement.appr_reject_by') }} </th>
                          <th scope="col"> {{ $t('globalTrans.designation') }} </th>
                          <th scope="col"> {{ $t('globalTrans.comments') }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td>
                            {{ getNameReceiver(item.appr_rejected_by) }}
                          </td>
                          <td>
                            {{ getForwardUserDesignation(item.appr_rejected_by) }}
                          </td>
                          <td>
                            {{ item.comments }}
                          </td>
                        </tr>
                    </tbody>
                </table>
                </div>
              </b-col>
            </b-row>
          </template>
        </body-card>
      </b-col>
    </b-overlay>
    </b-row>
  </div>
</template>
<script>
import RestApi, { bftiResReportServiceBaseUrl } from '@/config/api_config'
import { getForwardCommentsApi } from '../../../api/routes'
export default {
  name: 'Details',
  props: ['item'],
  data () {
    return {
      bftiResReportServiceBaseUrl: bftiResReportServiceBaseUrl,
      valid: null,
      loading: false,
      userLoading: false,
      errors: [],
      detailsData: [],
      detailsItemId: '',
      approveItem: '',
      forwardList: [],
      userList: [],
      designationList: []
    }
  },
  created () {
    if (this.item) {
      this.getForwardComments(this.item.id)
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getForwardComments (requestId) {
      this.userLoading = true
      RestApi.getData(bftiResReportServiceBaseUrl, `${getForwardCommentsApi}/${requestId}`).then(response => {
        if (response.success) {
          this.forwardList = response.forwardList
          this.userList = response.users
        }
        this.userLoading = false
      })
    },
    getNameReceiver (id) {
      const userName = this.userList.find(user => user.value === parseInt(id))
      if (userName !== undefined) {
        if (this.$i18n.locale === 'bn') {
          return userName.text_bn
        } else {
          return userName.text_en
        }
      }
    },
    getForwardUserDesignation (id) {
      const userName = this.userList.find(user => user.value === parseInt(id))
      if (userName !== undefined) {
        const designationObj = this.$store.state.CommonService.commonObj.designationList.find(deg => deg.value === parseInt(userName.designation_id))
        if (designationObj !== undefined) {
          return this.currentLocale === 'bn' ? designationObj.text_bn : designationObj.text_en
        }
      }
    },
     getDuration (startingDate, endingDate) {
            var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10))
            if (!endingDate) {
                endingDate = new Date().toISOString().substr(0, 10)
            }
            var endDate = new Date(endingDate)
            if (startDate > endDate) {
                var swap = startDate
                startDate = endDate
                endDate = swap
            }
            var startYear = startDate.getFullYear()
            var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28
            var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

            var yearDiff = endDate.getFullYear() - startYear
            var monthDiff = endDate.getMonth() - startDate.getMonth()
            if (monthDiff < 0) {
                yearDiff--
                monthDiff += 12
            }
            var dayDiff = endDate.getDate() - startDate.getDate()
            if (dayDiff < 0) {
                if (monthDiff > 0) {
                    monthDiff--
                } else {
                    yearDiff--
                    monthDiff = 11
                }
                dayDiff += daysInMonth[startDate.getMonth()]
            }

            if (this.$i18n.locale === 'bn') {
                const year = this.$n(yearDiff, { useGrouping: false })
                const month = this.$n(monthDiff, { useGrouping: false })
                const day = this.$n(dayDiff, { useGrouping: false })
                const result = year + ' বছর, ' + month + ' মাস, ' + day + ' দিন'
                return result
            } else {
                const result = yearDiff + ' Year, ' + monthDiff + ' Month, ' + dayDiff + ' Days'
                return result
            }
    }
  }
}
</script>
