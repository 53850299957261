const policy = '/policy-advocacy-management-system/policy-request-approval/policy-request-entry/'
export const policyRequestListApi = policy + 'list'
export const policyRequestStoreApi = policy + 'store'
export const policyRequestUpdateApi = policy + 'update'
export const policyRequestToggleApi = policy + 'toggle-status'
export const bftipolicyRequestReceivedList = policy + 'received-list'
export const bftipolicyRequestAdminList = policy + 'admin-list'
export const bftiPolicyRequestApproveRejectApi = policy + 'approve-reject'
export const policyRequestForwardApi = policy + 'forward'
export const policyRequestForwardListApi = policy + 'forwardList'
export const policyRequestApproveApi = policy + 'approve'
export const policyRequestRejectApi = policy + 'reject'
export const policyRequestForwardTrackApi = policy + 'forward-track'
export const getForwardCommentsApi = policy + 'forward-comments-list'
const bftipolicy = '/policy-advocacy-management-system/policy-request-approval/policy-approve-reject-list/'
export const policyApproveRejectListApi = bftipolicy + 'list'

export const userListByDesignationApi = 'user/users-by-designation-id'
